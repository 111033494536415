@font-face {
  font-family: 'THESANS';
  src: local('THESANS'), url(./THESANS.OTF) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


.App {
  text-align: center;
  width: 1080px;
  height: 1920px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  border-color: #8FB91C ;
  border-style: solid;
  border-width: 20px;
  font-family: 'THESANS','Courier New', Courier, monospace;
  overflow: hidden;
  
}

.thebg{
  background-image: url("./imgs/pag3.png");
  background-repeat: no-repeat;

}

.Title{
  font-size: 80px;
  color: #8FB91C;
  padding-top: 120px;
  font-weight: bold;
}

.Title h1{
  font-weight: bold;
}

.content{
  padding: 10px;
  font-size: 80px;


}

p{
  color: #36393A;
}

.btn{

  padding: 100px;
}

.App-header {
  margin-top: 279px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-left: 43px;
}

.App-link {
  color: #61dafb;
}

.colorCard {
  height: 350px;
  width: 350px;
  // margin-top:12px;
  // margin-right: 20px;
  border-radius: 50%;
  
}

.cardWrapper {
  display: inline-flex;
  width: 750px;
  flex-wrap: wrap;
  // margin-top: -18px;
  gap: 20px;
}


.startButton {
  position: absolute;
  background-color: #8FB91C;
  color: #36393A;
  font-family: 'THESANS', Arial, sans-serif;
  font-weight: bold;
  border-style: none;
  width: 370px;
  height: 370px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 60px;
  border-radius: 50%;
  top: 300px;
  left: 356px;
  
}

.score {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #36393A;
  border-radius: 50%;
  line-height: 100px;
  font-size: 50px;
  top: 398px;
  left: 500px;
}

.lost {
  position: absolute;
  width: 400px;
  height: 400px;
  background-color: #36393A;
  border-radius: 50%;
  line-height: 100px;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flash.flash {
  border: 1px solid white;
  box-sizing: border-box;
}

.red {
  //  background-color: red;
  &.flash {
    background-color: rgba(255, 255, 255, 0.394);
    border-radius: 50%;
  }
}

.blue {
   //background-color: blue;
  &.flash {
    background-color: rgba(255, 255, 255, 0.394);
  }
}

.green {
   //background-color: green;
  &.flash {
    background-color: rgba(255, 255, 255, 0.394);
  }
}

.yellow {
   //background-color: yellow;
  &.flash {
    background-color: rgba(255, 255, 255, 0.394);
  }
}
.gray {
   //background-color: gray;
  &.flash {
    background-color: rgba(255, 255, 255, 0.394);
  }
}
.lime {
   //background-color: lime;
  &.flash {
    background-color: rgba(255, 255, 255, 0.394);
  }
}

.btn-play{
  position: fixed;
  top: 1640px;
  left: 400px;

}

.btn-inicio{
  position: fixed;
  top: 10px;
  left: 10px;
  width: 100px;
  height: 100px;
  text-align: center;
  border-width: 1px;
  padding: 20px 20px 20px 12px !important;
  font-size: 30px !important;
}
.btn-inicio:hover{
  position: fixed;
  background-color: #36393A;
  color: #8FB91C;
  border-radius: 30px;
  top: 10px;
  left: 10px;
  width: 100px;
  height: 100px;
  text-align: center;
  border-width: 1px;
  padding: 20px 20px 20px 12px !important;
  font-size: 30px;
}

.btn-play:hover{

  background-color: #36393A;
  color: #8FB91C;
  border-radius: 30px;

}

.btn{
  background-color: #8FB91C;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 40px;
  

}

.btn:hover{
  border-color: #36393A;
  border-style: solid;
  border-width: 10px;
  color: #8FB91C;
  border-radius: 30px;


}

.tornillo{
  margin-top: 400px;
  position: absolute;
  top: 330px;
  left: 225px;
}

.progress-tornillo{
  position: absolute;
  width: 700px;
  height: 250px;
  top: 330px;
  left: 205px;

}

.fondo{
  z-index: 1;
  top: 0px;
}

